import { ServiceFactory } from '@/services/ServiceFactory'
// const _barMembersService = ServiceFactory.get('BarMembersService')
const _usersService = ServiceFactory.get('UsersService')

export default {
  data: () => ({
    dialog: false,
    show1: false,
    password1: '',
    show2: false,
    password2: '',
    show3: false,
    password3: '',
    enableToSubmit: true,
    errorMessage: [],
    inputError: false
  }),
  props: {
    user: Object
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      console.log('init')
      console.log(this.user)
    },
    verfiyPass () {
      console.log(this.password1, this.password2)
      if (this.password1 === this.password2) {
        this.enableToSubmit = false
        this.inputError = false
        this.errorMessage = []
        console.log('coincide')
      } else {
        this.enableToSubmit = false
        this.inputError = true
        this.errorMessage[0] = 'Las contraseñas no coinciden'
        console.log('coincide')
      }
    },
    async submit () {
      try {
        var payload = {
          rootPassword: this.password3,
          adminPassword: this.password2
        }
        const response = await _usersService.updatePassword(this.user.id, payload)
        console.log(response)
        this.$notify({
          type: 'success',
          text: 'Contraseña cambiada correctamente'
        })
        this.dialog = false
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: error.msg
        })
        this.dialog = false
      }
    }
  }
}
