var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":_vm.goToRouteFirst}},[_c('v-icon',[_vm._v("fas fa-arrow-left")])],1),_c('h1',{staticClass:"ml-2"},[_vm._v("Formulario de usuarios")])],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"label":"Nombre completo"},model:{value:(_vm.postData.name),callback:function ($$v) {_vm.$set(_vm.postData, "name", $$v)},expression:"postData.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"label":"Email"},model:{value:(_vm.postData.email),callback:function ($$v) {_vm.$set(_vm.postData, "email", $$v)},expression:"postData.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"label":"Nombre de usuario"},model:{value:(_vm.postData.username),callback:function ($$v) {_vm.$set(_vm.postData, "username", $$v)},expression:"postData.username"}})]}}],null,true)})],1),(!_vm.passBtn)?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show1 ? 'fas fa-eye' : 'fas fa-eye-slash',"type":_vm.show1 ? 'text' : 'password',"label":"Contraseña","required":"","error-messages":errors,"placeholder":"Contraseña","outlined":"","counter":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.postData.password),callback:function ($$v) {_vm.$set(_vm.postData, "password", $$v)},expression:"postData.password"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Cellphone","rules":"required|numeric|max:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"Celular"},model:{value:(_vm.postData.cellphone),callback:function ($$v) {_vm.$set(_vm.postData, "cellphone", $$v)},expression:"postData.cellphone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"BirthDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de nacimiento","outlined":"","readonly":"","error-messages":errors},model:{value:(_vm.postData.birthDate),callback:function ($$v) {_vm.$set(_vm.postData, "birthDate", $$v)},expression:"postData.birthDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker,"max":(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),"min":"1950-01-01"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save},model:{value:(_vm.postData.birthDate),callback:function ($$v) {_vm.$set(_vm.postData, "birthDate", $$v)},expression:"postData.birthDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Modules","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.modules,"item-text":"module","item-value":"zbModuleId","outlined":"","multiple":"","chips":"","error-messages":errors,"return-object":"","deletable-chips":"","label":"Cargo/Rol"},on:{"change":function($event){return _vm.appendModules($event)}},model:{value:(_vm.postData.modules),callback:function ($$v) {_vm.$set(_vm.postData, "modules", $$v)},expression:"postData.modules"}})]}}],null,true)})],1),_c('v-row',_vm._l((_vm.postData.modules),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"3"}},[_c('v-card',[_c('v-card-text',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 mb-1"},[_vm._v(" "+_vm._s(item.module)+" ")]),_c('div',[_vm._v(_vm._s(item.description))]),_c('v-list',[_c('validation-provider',{attrs:{"name":'Permissions'+index,"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-list-item',[_c('v-checkbox',{attrs:{"label":"Ver","name":'mod_permissions'+index,"color":"blue","value":"GRANTED","hide-details":"","error-messages":errors,"input-value":item.permissions['VIEW'] === 'GRANTED'? 'GRANTED':null},on:{"change":function($event){return _vm.permissionCheck($event, item, 'View')}}})],1),_c('v-list-item',[_c('v-checkbox',{attrs:{"label":"Crear","name":'mod_permissions'+index,"color":"green","value":"GRANTED","hide-details":"","error-messages":errors,"input-value":item.permissions['CREATE'] === 'GRANTED'? 'GRANTED':null},on:{"change":function($event){return _vm.permissionCheck($event, item, 'Create')}}})],1),_c('v-list-item',[_c('v-checkbox',{attrs:{"label":"Editar","name":'mod_permissions'+index,"color":"yellow","value":"GRANTED","hide-details":"","error-messages":errors,"input-value":item.permissions['EDIT'] === 'GRANTED'? 'GRANTED':null},on:{"change":function($event){return _vm.permissionCheck($event, item, 'Edit')}}})],1),_c('v-list-item',[_c('v-checkbox',{attrs:{"label":"Eliminar","name":'mod_permissions'+index,"color":"red","value":"GRANTED","hide-details":"","error-messages":errors,"input-value":item.permissions['DELETE'] === 'GRANTED'? 'GRANTED':null},on:{"change":function($event){return _vm.permissionCheck($event, item, 'Delete')}}})],1),(errors[0])?_c('div',{staticClass:"red--text mt-6"},[_vm._v("La casilla 'Ver' es obligatoria")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)],1)}),1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"accent","type":"submit","disabled":invalid,"loading":_vm.loading}},[_vm._v("Guardar")])],1),_c('v-col',{attrs:{"cols":"8"}},[(_vm.passBtn)?_c('ZbPasswordComponent',{attrs:{"user":_vm.userData}}):_vm._e()],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }