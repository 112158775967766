import ZbPasswordComponent from './password-modal'
import { ServiceFactory } from '@/services/ServiceFactory'
const _modulesService = ServiceFactory.get('ModulesService')
const _usersService = ServiceFactory.get('UsersService')

export default {
  name: 'ZbUsersForm',
  components: {
    ZbPasswordComponent
  },
  data () {
    return {
      // tab: null,
      // items: [
      //   'Usuario', 'Roles'
      // ],
      postData: {
        name: '',
        username: '',
        email: '',
        cellphone: null,
        password: '',
        birthDate: '',
        modules: []
      },
      modules: [],
      loading: false,
      loading2: false,
      selectedModules: 0,
      menu: false,
      activePicker: null,
      edit: false,
      passBtn: false,
      userData: {
        id: '',
        name: ''
      },
      show1: false,
      permissionsCheck: [[]]
    }
  },
  watch: {
    // tab (newVal, oldVal) {
    //   switch (newVal) {
    //     case 0:
    //       break
    //     case 1:
    //       break
    //     default:
    //       break
    //   }
    // }
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      try {
        this.loading2 = true
        const modulesResp = await _modulesService.index()
        console.log(modulesResp)
        this.modules = modulesResp.modules
        this.passBtn = false
        if (this.$route.params.user) {
          console.log('edit', this.$route.params.user)
          this.edit = true
          this.postData = this.$route.params.user
          this.userData.id = this.$route.params.user.zbUserId
          this.userData.name = this.$route.params.user.name
          this.passBtn = true
        }

        this.loading2 = false
        this.selectedModules = this.postData.modules.length
      } catch (error) {
        console.log(error)
        this.loading2 = false
      }
    },
    appendModules (item) {
      // console.log(this.postData.modules)
      var currentSelected = this.postData.modules.length
      var last = item[item.length - 1]
      // .permissions = {
      //   'VIEW': 'DENIED',
      //   'EDIT': 'DENIED',
      //   'DELETE': 'DENIED',
      //   'CREATE': 'DENIED'
      // }
      console.log(currentSelected, this.selectedModules)
      if (currentSelected > this.selectedModules) {
        console.log('Aumento seleccion')
        this.postData.modules[this.postData.modules.length - 1].permissions = {
          'VIEW': 'GRANTED',
          'EDIT': 'DENIED',
          'DELETE': 'DENIED',
          'CREATE': 'DENIED'
        }
      } else {
        console.log('disminuyo seleccion')
      }
      this.selectedModules = currentSelected
      console.log(item, last, currentSelected, this.postData.modules)
    },
    permissionCheck (e, item, perm) {
      console.log(e, item)
      if (perm === 'Create') {
        if (e) {
          item.permissions.CREATE = e
        } else {
          item.permissions.CREATE = 'DENIED'
        }
      }
      if (perm === 'Edit') {
        if (e) {
          item.permissions.EDIT = e
        } else {
          item.permissions.EDIT = 'DENIED'
        }
      }
      if (perm === 'View') {
        if (e) {
          item.permissions.VIEW = e
        } else {
          item.permissions.VIEW = 'DENIED'
        }
      }
      if (perm === 'Delete') {
        if (e) {
          item.permissions.DELETE = e
        } else {
          item.permissions.DELETE = 'DENIED'
        }
      }
      console.log(item)
    },
    async submit () {
      console.log(this.postData)
      if (this.edit) {
        try {
          this.loading = true
          const usersEditResponse = await _usersService.edit(this.postData.zbRootId, this.postData)
          console.log(usersEditResponse)
          this.loading = false
          this.$notify({
            type: 'success',
            text: 'Usuario root editado correctamente'
          })
          this.$router.push({ name: 'users-table' })
        } catch (error) {
          console.log(error)
          this.loading = false
          this.$notify({
            type: 'error',
            text: `Algo salió mal al editar al usuario, vuelva a intentarlo nuevamente`
          })
        }
      } else {
        try {
          this.loading = true
          const usersResponse = await _usersService.create(this.postData)
          console.log(usersResponse)
          this.loading = false
          this.$notify({
            type: 'success',
            text: 'Nuevo usuario root registrado correctamente'
          })
          this.$router.push({ name: 'users-table' })
        } catch (error) {
          console.log(error)
          this.loading = false
          this.$notify({
            type: 'error',
            text: `Algo salió mal al registrar al usuario, vuelva a intentarlo nuevamente`
          })
        }
      }
    },
    goToRouteFirst () {
      this.$router.push({ path: '/admin/users' }).catch(() => {})
    },
    save (date) {
      this.$refs.menu.save(date)
    }
  }
}
